:root {
  --ck-border-radius: 8px !important;
}

body * {
  font-family: Poppins;
}

// #zmmtg-root {
//   z-index: -1;
// }

.chakra-accordion__item {
  border-left-width: 1px;
  border-right-width: 1px;
}

.swal2-popup {
  border-radius: 48px !important;
  width: 28em !important;
}

.swal2-styled.swal2-confirm {
  background-color: #005081 !important;
}

#root {
  height: 100%;
}
.rug .rug-handle:before {
  border-color: #eee !important;
}

.rug-handle-drop-text {
  display: none;
}

.rug-handle-info {
  position: relative;
}

.rug-handle-info::after {
  content: "Arraste as imagens aqui";
  position: absolute;
  top: -24px;
  font-size: 24px;
  width: 300px;
  left: 52%;
  transform: translateX(-50%);
}

.ant-modal-body::-webkit-scrollbar,
.custom-scroll-bar::-webkit-scrollbar {
  background-color: #fff;
  width: 18px;
}

.ant-modal-body::-webkit-scrollbar-track,
.custom-scroll-bar::-webkit-scrollbar-track {
  background-color: #fff;
}

.ant-modal-body::-webkit-scrollbar-thumb,
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

.chakra-input {
  background-color: #eff4ff !important;
  font-size: 14px !important;
  border-color: #eff4ff !important;
}

.ant-picker {
  background-color: #eff4ff;
  font-size: 14px;
  border-color: #eff4ff;
}

.ant-picker {
  height: 40px;
}

.chakra-checkbox__label,
.chakra-radio__label {
  font-size: 14px !important;
}

[aria-invalid="true"] {
  background-color: #fff1f1 !important;
}

.ant-modal-mask {
  border-radius: 0 !important;
}

.input-toggle-collapse .ant-collapse-content-box {
  padding-left: 0px;
  padding-right: 0px;
}

// FULL CALENDAR

.fc-toolbar-title {
  text-transform: capitalize;
  font-size: 20px !important;
  color: #666666 !important;
  margin-bottom: -4px !important;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc-daygrid-day,
.fc-daygrid-day-frame {
  z-index: 1;
}

.fc-daygrid-event {
  overflow: hidden;
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.fc-daygrid-event:hover {
  overflow: visible;
  z-index: 999;
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.fc-daygrid-event-harness {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.fc-list-event-dot {
  opacity: 0;
}

.fc-button-primary:not(.fc-next-button):not(.fc-prev-button) {
  border-width: 2px;
  border-radius: 10px;
  height: 42px;
  width: 90px;
}

.fc-button-primary:not(.fc-button-active):not(.fc-next-button):not(.fc-prev-button):not(.fc-changeView-button) {
  background-color: white !important;
  color: #979797 !important;
  border-color: #dadada !important;
}

.fc-button-active,
.fc-changeView-button {
  background-color: #5d5fef !important;
  border-color: #5d5fef !important;
  color: white !important;
}

.fc-dayGridMonth-button {
  margin-left: -2px;
}

.fc-col-header-cell-cushion {
  text-transform: capitalize;
}

.fc-next-button,
.fc-prev-button {
  border: none !important;
  background: none !important;
  color: #a3a3a3 !important;
  width: fit-content !important;
  padding: 0 !important;
}

.fc-list-day-side-text {
  text-transform: capitalize;
}

// Antd select
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #eff4ff;
  border: none;
  border-radius: 6px;
}

.ant-picker {
  border-radius: 8px;
}

.ant-select.antd-select-white-bg.antd-select-force-white-bg
  > .ant-select-selector {
  background-color: white !important;
  height: 40px;
  border-color: #b5becd !important;
  border-width: 1px;
}

.ant-select.antd-select-white-bg.antd-select-force-white-bg
  > .ant-select-selector
  .ant-select-selection-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
}

.ant-select.antd-select-white-bg.antd-select-force-white-bg
  > .ant-select-selector
  .ant-select-selection-item {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.ant-picker-dropdown {
  z-index: 2;
}

.ant-picker-cell-inner {
  text-transform: capitalize;
}

.ant-picker input {
  text-transform: capitalize;
  font-weight: 400;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  color: #1a202c;
}

.ant-picker-focused {
  border-color: #5d5fef;
}

.antd-popover-hide-arrow .ant-popover-arrow {
  display: none !important;
}

.ant-modal-content {
  border-radius: 8px;
}

.antd-no-background .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.antd-custom-header .ant-modal-header {
  padding: 0 !important;
}

.antd-custom-header .ant-modal-content, .antd-custom-header .ant-modal-header {
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
}

span.ant-select-tree-switcher {
  display: flex !important;
  justify-content: center;
  align-items: center;
  transform: scale(1.2);
}

span.ant-select-tree-node-content-wrapper {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

span.ant-select-tree-title {
  font-size: 14px;
}

// SHOW-MORE-TEXT

.show-more-text-perguntas {
  font-size: 13px;
  font-weight: medium;
  line-height: 16px;
  text-indent: 8px;
  text-align: start;
}

.show-more-anchor {
  color: #22a6f0;
}

.show-more-anchor:hover {
  color: #00aaff;
  opacity: 0.8;
}

// Antd modal

.ant-modal-rounded .ant-modal-content {
  border-radius: 16px;
}

.ant-modal-rounded .ant-modal-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.ant-modal-header {
  border-bottom: none;
}

.ant-modal-footer {
  border-top: none;
}

// HTML DA PROCURAÇÃO

.container-procuracao-html {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo-procuracao-html {
    margin-top: 3cm;
    margin-bottom: 4cm;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
  }

  .corpo-procuracao-html {
    text-align: justify;
    width: 100%;
    font-size: 16px;
    margin-bottom: 4cm;
  }

  .data-procuracao-html {
    text-align: start;
    width: 100%;
    font-size: 16px;
    margin-bottom: 4cm;
  }

  .assinatura-procuracao-html {
    display: flex;
    flex-direction: column;
    align-items: center;

    .imagem-assinatura-procuracao-html {
      width: 100%;
      height: 2cm;
      object-fit: contain;
      margin-bottom: -8px;
    }

    .linha-assinatura-procuracao-html {
      height: 2px;
      border-top-width: 2px;
      border-top-color: black;
      min-width: 12cm;
    }

    .descricao-assinatura-procuracao-html {
      font-size: 16px;
      text-align: center;
    }
  }
}

//CK EDITOR

.ck-editor-height-1 .ck-content {
  min-height: 300px;
}

.ck-editor-height-2 .ck-content {
  min-height: 150px;
}

.ck-editor__main {
  cursor: text;
}

.ck-editor h1 {
  font-size: 20px;
  font-weight: bold;
}

.ck-editor h2 {
  font-size: 18px;
  font-weight: medium;
}

.ck-editor h3 {
  font-size: 16px;
}

.ck-editor p {
  font-size: 14px;
}

.ck-editor ul {
  padding-left: 16px;
}

.ck-editor ol {
  padding-left: 16px;
}

.ck-editor ol li {
  padding-left: 4px;
}

.ck-editor blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
}

.ck-font-size-dropdown > .ck-dropdown__panel {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}
